<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { artisteService } from '../../../../services/artiste.service';
import { filesBaseUrl } from '../../../../constants/config';
import { customFunction } from '../../../../utils/customFunction';
import moment from 'moment';
import {
    required,
    email
} from "vuelidate/lib/validators";
import VsToast from '@vuesimple/vs-toast';

export default {
    page: {
        title: "Artistes",
        meta: [{ name: "description", content: appConfig.description }]
    },
    components: { Layout, PageHeader },
    data() {
        return {
            artistes: [],
            items: [],
            filesBaseUrl,
            title: "Artistes",
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 25, 50, 100],
            filter: null,
            filterOn: [],
            sortBy: "nom_scene",
            sortDesc: false,
            fields: [
                { key: "image", label: 'Image', sortable: false },
                { key: "nom_scene", label: 'Nom de scene', sortable: true },
                { key: "bio", label: "Biographie", sortable: false },
                { key: "email", label: "Email", sortable: true },
                { key: "options", sortable: false, thClass: !this.verifAdmin() ? 'd-none' : '', tdClass: !this.verifAdmin() ? 'd-none' : '' }
            ],
            form: {
                nom_scene: "",
                bio: "",
                email: ""
            },
            submitted: false,
            showForm: false,
            imageArtiste: null,
            isUpdate: false,
            updateId: null
        };
    },
    validations: {
        form: {
            nom_scene: { required },
            email: { required, email },
            bio: { required }
        },
    },
    computed: {
        /**
         * Total no. of records
         */
        rows() {
            return this.artistes.length;
        }
    },
    mounted() {
        this.getArtistes();
        // Set the initial number of items
        this.totalRows = this.items.length;
    },
    methods: {
        /**
         * Search the table data with search input
         */
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        async getArtistes() {
            this.artistes = await artisteService.get();
        },
        verifAdmin() {
            return customFunction.verifAdmin();
        },
        
        hideForm() {
            this.showForm = false;
            this.submitted = false;
            this.isUpdate = false;
            this.form.nom_scene = '';
            this.form.password = '';
            this.form.email = '';
            this.imageArtiste = null;
            this.form.bio = '';
           
        },
        formateDateForm(d) {
            return moment(d).format('YYYY-MM-DD');
        },
        async showUpdate(item) {
            this.showForm = true;
            this.isUpdate = true;
            this.updateId = item.id;
            this.form.nom_scene = item.nom_scene;
            this.form.bio = item.bio;
            this.form.email = item.email;
            this.form.password = item.user.password;
            this.imageArtiste = null;
        },
        async formSubmit() {
            this.submitted = true
            this.$v.$touch();
            console.log(this.$v.$error);
            if (!this.$v.$error) {

                if (this.imageArtiste == null && !this.isUpdate) {
                    VsToast.show({
                        title: 'Erreur champs',
                        message: 'Selectionner une image',
                        variant: 'warning',
                    })
                    return;
                }

                let forms = new FormData();

                forms.append('nom_scene', this.form.nom_scene);
                forms.append('bio', this.form.bio);
                forms.append('email', this.form.email);
                if (this.imageArtiste != null) forms.append('image', this.imageArtiste.file);

                const rep = this.isUpdate ? await artisteService.update(forms, this.updateId) : await artisteService.create(forms);

                if (rep >= 400) {

                    VsToast.show({
                        title: 'Veuillez ressayer',
                        message: 'Une erreur s\'est produite',
                        variant: 'danger',
                    })

                } else {

                    VsToast.show({
                        title: this.isUpdate ? 'Mise à jour effectué' : 'Enregistrement effectué',
                        message: '',
                        variant: 'success',
                    });

                    this.artistes = await artisteService.get();
                    this.hideForm();
                }

            }
        },
    }
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row mb-2">
            <div v-if="!showForm && verifAdmin()" class="col-md-8">
                <button @click="showForm = true" class="btn btn-primary">Ajouter</button>
            </div>
        </div>
        <form v-if="showForm" class="needs-validation mb-2" @submit.prevent="formSubmit">
            <div class="row">
                <div class="col-md-6">
                    <div class="mb-3">
                        <label class="form-label" for="validationCustom01">Nom d'artiste</label>
                        <input id="validationCustom01" v-model="form.nom_scene" type="text" class="form-control"
                            placeholder="Nom d'artiste" value="Mark" :class="{
                                'is-invalid': submitted && $v.form.nom_scene.$error,
                            }" />
                        <div v-if="submitted && $v.form.nom_scene.$error" class="invalid-feedback">
                            <span v-if="!$v.form.nom_scene.required">Champs requis.</span>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                        <div class="mb-3">
                        <label class="form-label" for="validationCustom01">Email (les identifiants seront envoyés cet email)</label>
                        <input
                            id="validationCustom01"
                            v-model="form.email"
                            type="email"
                            class="form-control"
                            placeholder="Email"
                            value="Mark"
                            :class="{
                                'is-invalid': submitted && $v.form.email.$error,
                            }"
                        />
                        <div
                            v-if="submitted && $v.form.email.$error"
                            class="invalid-feedback"
                        >
                            <span v-if="!$v.form.email.required"
                            >Champs requis.</span
                            >
                            <span v-if="!$v.form.email.email"
                            >Entrer une email valide.</span
                            >
                        </div>
                        </div>
                </div>
            </div>

            <div class="col-md-12">
                <label class="form-label">Biographie</label> <br>
                <textarea name="" class="form-control" v-model="form.bio" cols="30" rows="5" :class="{
                    'is-invalid': submitted && $v.form.bio.$error,
                }"></textarea>
            </div>

            <div class="col-md-6 mb-2">
                <label for="">Image</label>
                <VueFileAgent v-model="imageArtiste" :multiple="false" :deletable="true"
                    :accept="'image/png, image/jpeg, image/jpg, image/wepp'" :maxSize="'2MB'" :maxFiles="1"
                    :helpText="'Sélectionner l\'image'" :errorText="{
                        type: 'Type de fichier invalid',
                        size: 'Votre fichier ne peux excéder 2Mo',
                    }">
                </VueFileAgent>
            </div>

            <button v-if="!isUpdate" class="btn btn-primary" type="submit">Enregistrer</button>
            <button v-if="isUpdate" class="btn btn-primary" type="submit">Mettre à jour</button>
            <button @click="hideForm()" class="btn btn-secondary" style="margin-left: 3px;" type="submit">Annuler</button>
        </form>
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <p class="card-title-desc"></p>
                        <div class="row mb-md-2">
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_length" class="dataTables_length">
                                    <label class="d-inline-flex align-items-center">
                                        Show
                                        <b-form-select class="form-control form-control-sm form-select form-select-sm"
                                            v-model="perPage" size="sm" :options="pageOptions"></b-form-select>entries
                                    </label>
                                </div>
                            </div>
                            <!-- Search -->
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_filter" class="dataTables_filter text-md-end">
                                    <label class="d-inline-flex align-items-center">
                                        Search:
                                        <b-form-input v-model="filter" type="search" placeholder="Search..."
                                            class="form-control form-control-sm ml-2"></b-form-input>
                                    </label>
                                </div>
                            </div>
                            <!-- End search -->
                        </div>
                        <!-- Table -->
                        <div class="table-responsive mb-0 datatables">
                            <b-table :items="artistes" :fields="fields" responsive="sm" :per-page="perPage"
                                :current-page="currentPage" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
                                :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered">
                                <template v-slot:cell(image)="r">
                                    <img class="rounded-circle header-profile-user" :src="`${filesBaseUrl}/artiste/${r.item.image}`" height="50" alt="">
                                </template>

                                <template v-slot:cell(options)="r">
                                    <button v-if="verifAdmin()" @click="showUpdate(r.item)" title="Mettre à jour"
                                        class="btn btn-warning btn-sm"><i class="fa fa-edit"></i></button>
                                </template>
                            </b-table>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="dataTables_paginate paging_simple_numbers float-end">
                                    <ul class="pagination pagination-rounded mb-0">
                                        <!-- pagination -->
                                        <b-pagination v-model="currentPage" :total-rows="rows"
                                            :per-page="perPage"></b-pagination>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>